<template>
  <div class="home">
    <el-card class="box-card thinbody">
      <div>
        <el-button type="primary" @click="create1">新建视频</el-button>
      </div>
    </el-card>

    <el-dialog
      v-model="dialogVisible"
      :title="modelTitle"
      width="70%"
      top="70px"
      append-to-body
      destroy-on-close
      center
    >
      <!-- <engine :id="modelId"></engine> -->
      <video
        controls
        preload="preload"
        autoplay
        style="width: 1280px; height: 720px"
        :src="'/resource/publish/' + modelId + '.mp4'"
      ></video>
    </el-dialog>

    <el-table
      :data="resList"
      style="width: 100%"
      :stripe="true"
      :header-cell-style="{ 'text-align': 'center' }"
    >
      >
      <el-table-column prop="id" label="id" width="60" align="center" />
      <el-table-column prop="renderStatus" width="260" label="发布状态" align="left">
        <template #default="scope">
          <el-button type="text" @click="render(scope.row.id)">[加入渲染]</el-button>

          <span v-if="scope.row.renderStatus == 0" style="background-color: azure"> 编辑中 </span>
          <span v-if="scope.row.renderStatus == 1" style="background-color: azure; color: #000">
            排队
          </span>
          <span v-if="scope.row.renderStatus == 2" style="background-color: azure; color: #f00">
            渲染中
          </span>
          <span v-if="scope.row.renderStatus == 9" style="background-color: chartreuse">
            已完成
          </span>
          <a target="_blank" :href="'/editor/' + scope.row.id">[编辑]</a>
          <el-button type="text" @click="openModal(scope.row)">[预览]</el-button>

          <!-- <router-link :to="'/player/' + scope.row.id" target="_blank"
            ><el-button type="text" size="small" @click="handleClick">预览</el-button></router-link
          > -->
          <a
            @click="checkFile('/resource/publish/' + scope.row.id + '.mp4')"
            :href="'/resource/publish/' + scope.row.id + '.mp4'"
            :download="scope.row.name + '.mp4'"
            >[下载]</a
          >
        </template>
      </el-table-column>
      <el-table-column prop="name" label="资源名称" align="left">
        <template #default="scope">
          <a href="####" @click="openModal(scope.row)"> {{ scope.row.name }} </a>
        </template>
      </el-table-column>
      <el-table-column prop="course" label="课程名称" width="220" align="center"></el-table-column>
      <el-table-column prop="teacher" label="教师名称" width="220" align="center"></el-table-column>

      <el-table-column label="操作" width="120" align="center">
        <template #default="scope">
          <!-- <el-button type="text" @click="openModal(scope.row)">预览</el-button> -->
          <span>&nbsp;&nbsp;</span>
          <!-- <router-link :to="'/player/' + scope.row.id" target="_blank"
            ><el-button type="text" size="small" @click="handleClick">预览</el-button></router-link
          > -->
          <router-link :to="'/editor/' + scope.row.id" target="_blank"
            ><el-button type="primary" size="mini">编辑</el-button></router-link
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pager">
      <el-pagination
        background
        style="display: flex; align-items: center"
        v-model:currentPage="pager.pageNumber"
        :page-sizes="[10, 20, 50, 100, 200, 300, 400]"
        :pager-count="15"
        v-model:page-size="pager.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pager.pageTotal"
        @size-change="loadData"
        @current-change="loadData"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ObjectID from "bson-objectid";
// import engine from "../../components/player/engine.vue";
import { ElMessage } from "element-plus";
import _ from "lodash";

export default {
  name: "workplace",
  components: {},
  data() {
    return {
      resList: [],
      pager: {
        pageNumber: 1,
        pageSize: 20,
        pageTotal: 0,
      },
      dialogVisible: false,
      modelId: 0,
      modelTitle: "",
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      // let _id = this.$route.params.id;
      //console.log(_id);

      let that = this;

      let url = `/api/project/GetPageList?pageNumber=${that.pager.pageNumber}&pageSize=${that.pager.pageSize}`;

      var config = {
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      axios(config)
        .then(function (response) {
          let _data = response.data.result;
          console.log(_data);
          that.resList = _data.list;
          that.pager.pageTotal = _data.pageTotal;
          that.pager.pageNumber = _data.pageNumber;
          that.pager.pageSize = _data.pageSize;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleEdit(row) {
      console.log(row);
      this.$router.push({ name: "editor", params: { id: row.id } });
    },
    create1() {
      let project1 = {
        uuid: ObjectID().toHexString(),
        name: "新建视频1",
        sences: [
          {
            id: ObjectID().toHexString(),
            name: "sence1",
            sprites: [],
          },
        ],
      };

      var meta = JSON.stringify(project1);

      let that = this;
      axios
        .post(
          "/api/project/create",
          { name: project1.name, meta: meta },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(function (response) {
          console.log(response);
          that.loadData();
        });
    },
    openModal(row) {
      this.dialogVisible = true;
      this.modelId = row.id;
      this.modelTitle = row.name;
    },
    checkFile(url) {
      //event.preventDefault();
      var request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.onreadystatechange = function () {
        if (request.readyState === 4) {
          if (request.status === 404) {
            //alert("Oh no, it does not exist!");
            ElMessage({ message: "服务器文件不存在，请先渲染", duration: 500 });
            return false;
          }
        }
      };
      request.send();
    },
    render(id) {
      var that = this;
      axios.post("/api/Render/Start/" + id).then(function (response) {
        console.log(response.data);
        ElMessage({ message: "加入渲染队列成功！", duration: 500 });
        _.filter(that.resList, function (o) {
          if (o.id == id) {
            o.renderStatus = 1;
          }
        });
      });
    },
  },
};
</script>

<style>
.pager {
  padding: 10px;
}
.el-table td,
.el-table th {
  padding: 3px 0;
}

.thinbody {
  padding: 0px;
}

.el-card__body {
  padding: 10px;
}
</style>
