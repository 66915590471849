<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>我的项目</span>
      </div>
    </template>
    <div>
      <el-upload
        style="margin-left: 10px; margin-right: 10px"
        action="/api/upload/create"
        :show-file-list="false"
        :data="{ projectId: userId }"
        :on-success="loadData"
        :headers="headers"
        multiple
      >
        <el-button size="small" type="primary">插入图片、音频、视频、flash</el-button>
      </el-upload>
    </div>
  </el-card>

  <el-table
    :data="resList"
    style="width: 100%"
    :stripe="true"
    :header-cell-style="{ 'text-align': 'center' }"
  >
    >
    <el-table-column prop="id" label="id" width="60" align="center" />
    <el-table-column prop="name" label="资源名" width="320" align="center"></el-table-column>

    <el-table-column prop="previewImage" label="缩略图" width="320" align="center">
      <template #default="scope">
        <img style="width: 12px; height: 9px" :src="scope.row.previewImage" />
      </template>
    </el-table-column>

    <el-table-column prop="type" label="类型" width="120" align="center" />
    <el-table-column prop="extension" label="扩展名" align="left" />

    <el-table-column label="操作" width="120" align="center">
      <template #default>
        <el-button type="text" size="small" @click="handleClick">详情</el-button>
        <el-button type="text" size="small">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>

  <div class="pager">
    <el-pagination
      style="display: flex; align-items: center"
      v-model:currentPage="pager.pageNumber"
      :page-sizes="[10, 20, 50, 200, 300, 400]"
      v-model:page-size="pager.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.pageTotal"
      :pager-count="30"
      @size-change="loadData"
      @current-change="loadData"
    >
    </el-pagination>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "myresource",
  data() {
    return {
      userName: localStorage.getItem("userName"),
      userId: localStorage.getItem("userId"),
      fileList: [],
      resList: [],
      pager: {
        pageNumber: 1,
        pageSize: 10,
        pageTotal: 20,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      // let _id = this.$route.params.id;
      //console.log(_id);

      let that = this;

      let url = `/api/resource/GetPageList?pageNumber=${this.pager.pageNumber}&pageSize=${this.pager.pageSize}`;

      var config = {
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      axios(config)
        .then(function (response) {
          let _data = response.data.result;
          that.resList = _data.list;
          that.pager.pageTotal = _data.pageTotal;
          that.pager.pageNumber = _data.pageNumber;
          that.pager.pageSize = _data.pageSize;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getToken() {
      var token = localStorage.getItem("token"); // 要保证取到
      return { Authorization: token };
    },
  },
};
</script>

<style scoped></style>
