<template>
  <div class="wrapper">
    <div class="header">
      <div class="brand">微课视频动画智能创作平台</div>
      <div>
        <!-- <router-link to="/editor" style="margin-left: 15px"
          ><el-button type="success">进入编辑器</el-button></router-link
        > -->
      </div>
      <div class="right">
        <el-button type="text">{{ userId }}</el-button>
        <el-button type="text">{{ userName }}</el-button>
        <el-button type="info">个人信息</el-button>
        <el-button type="warning">
          <router-link :to="{ path: '/login' }">退出</router-link></el-button
        >
      </div>
    </div>
    <div class="container">
      <div class="left">
        <div class="left_wrapper">
          <router-link to="/user/workplace"
            ><el-icon><add-location /></el-icon>工作台</router-link
          >
          <router-link to="/user/myproject"
            ><el-icon><calendar /></el-icon>我的项目</router-link
          >

          <router-link to="/user/publibrary"
            ><el-icon><eleme /></el-icon>公共资源库</router-link
          >
          <router-link to="/user/myresource"
            ><el-icon><circle-check-filled /></el-icon>我的资源库</router-link
          >

          <router-link to="/user/wait1"
            ><el-icon><eleme /></el-icon>项目统计</router-link
          >
          <router-link to="/user/wait2"
            ><el-icon><expand /></el-icon>资源统计</router-link
          >
          <router-link to="/user/wait3"
            ><el-icon><avatar /></el-icon>个人中心</router-link
          >
        </div>
      </div>
      <div class="content">
        <div class="content_wrapper">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "user_index",
  data() {
    return {
      userName: localStorage.getItem("userName"),
      userId: localStorage.getItem("userId"),
    };
  },
};
</script>

<style scoped>
.wrapper {
  background-color: #1ea7a0;
  height: 100%;

  display: flex;
  flex-direction: column;

  min-width: 1300px;
  min-height: 700px;
}
.header {
  background-color: #001529;
  height: 60px;
  flex-shrink: 0;
  color: aliceblue;
  position: fixed;
  width: 100%;
  z-index: 2001;

  display: flex;
  align-items: center;
}
.header > div {
  padding-left: 15px;
  padding-right: 15px;
}
.header .brand {
  width: 200px;
}
.header > div.right {
  flex: 1;
  text-align: right;
}
.container {
  background-color: rgb(118, 179, 22);
  flex-grow: 1;

  display: flex;
  flex-direction: row;
}
.left {
  background-color: #001529;
  width: 200px;
  position: fixed;
  height: 100%;
  z-index: 2000;
}
.content {
  background-color: #f0f2f5;
  flex-grow: 1;
  overflow-y: auto;
  margin-left: 200px;
}

.left_wrapper {
  padding-top: 60px;
}
.left_wrapper a {
  display: block;
  padding: 15px;
  padding-left: 50px;
  text-align: left;
  font-size: 14px;
  color: #a6adb4;
  text-decoration: none;
}
.left_wrapper a.router-link-active {
  background-color: #1890ff;
  color: #fff;
}

.content_wrapper {
  padding-top: 80px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>
