<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>统计中</span>
      </div>
    </template>
    <div>生成中</div>
  </el-card>
</template>

<script>
export default {
  name: "wait1",
};
</script>

<style scoped></style>
