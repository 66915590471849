<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>公共资源库</span>
      </div>
    </template>
    <div>
      <el-tabs :tab-position="tabPosition">
        <el-tab-pane label="角色">
          <lottieResource></lottieResource>
        </el-tab-pane>
        <el-tab-pane label="背景">
          <bgResource></bgResource>
        </el-tab-pane>
        <el-tab-pane label="素材">
          <resResource></resResource>
        </el-tab-pane>
        <el-tab-pane label="文字">
          <textResource></textResource>
        </el-tab-pane>
        <el-tab-pane label="声音">
          <musicResource></musicResource>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-card>
</template>

<script>
import lottieResource from "@/components/editor/resource/lottieResource";
import bgResource from "@/components/editor/resource/bgResource";
import resResource from "@/components/editor/resource/resResource";
import textResource from "@/components/editor/resource/textResource";
import musicResource from "@/components/editor/resource/musicResource";

export default {
  name: "publibrary",
  components: {
    lottieResource,
    bgResource,
    resResource,
    textResource,
    musicResource,
  },
};
</script>

<style scoped></style>
